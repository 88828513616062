@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@500&display=swap');

img {
    width: 80px;
}

body, html {
    margin: 0;
    padding: 0;
    background: white;
    overflow-x: hidden;
}

.wholePageContainer {
    display: flex;
    height: 100vh;
}

.flexContainer {
    display: flex;
}

.flexContainer2 {
    display: flex;
    justify-content: space-between;
}

.flexContainer3 {
    display: flex;
    max-width: 340px;
}

.screenLeft {
    min-width: 450px;
}

.screenRight {

    display: flex;
    align-items: center;
    min-width: 217px;
    flex-grow: 1;

}

.styleOfInput {

    border-radius: 7px;
    height: 33px;
    border: 1px solid black;
    padding-left: 15px;

}

.font {
    font-weight: 500;
    font-family: 'Space Grotesk', sans-serif;
}

.textAlignment {
    margin: 0 0 10px 0;
}

.one {
    margin-bottom: 20px;
    border: black solid 1px;
    outline: none;
    max-width: 350px;
}

.oneV2 {
    margin-bottom: 20px;
    max-width: 350px;
    border: red solid 1px;
    outline: none;
}

.two {
    max-width: 35px;
    min-width: 20px;
    margin: 10px 7px 20px 0;
    outline: none;

}

.twoV2 {
    margin: 10px 7px 20px 0;
    border: 1px red solid;
    max-width: 35px;
    min-width: 20px;
    outline: none;
}

.threeV2 {
    flex-grow: 1;
    margin: 10px 0 15px 15px;
    max-width: 213px;
    min-width: 0;
    border: red solid 1px;
    outline: none;
}

.three {
    flex-grow: 1;
    margin: 10px 0 15px 15px;
    max-width: 215px;
    min-width: 0;
    outline: none;
}

.button {
    margin: 20px 0 0 0;
    background: #151414;
    max-width: 369px;
    height: 50px;
    color: white;
    text-align: center;
    border-radius: 7px;
}

.spaceBetween {
    margin-left: 25px;
}

.inputContainer {
    display: flex;
    flex-flow: column;
    flex-grow: 3;
    margin: 0 20px 0 0;
    padding: 50px 0 50px 50px;
    min-width: 217px;
}

/*Card Layout*/

.frontSideOfCard {
    position: relative;
    left: 130px;
    top: 200px;
    height: 280px;

    display: flex;
    flex-flow: column;

    padding: 20px;
}

.backSideOfCard {
    position: relative;
    left: 210px;
    top: 150px;
    height: 280px;

}

.textOrder {
    margin-top: 70px;
    font-size: 32px;
    color: white;
}

.textOrder2 {
    margin-top: 20px;
    color: white;
}

.textOrder3 {
    position: relative;
    color: white;
    top: 110px;
    left: 369px;
}

.textOrder4 {
    position: relative;
    color: red;
    flex-grow: 1;
    bottom: 8px;
    font-size: 12px;
}

.textOrder5 {
    position: relative;
    color: red;
    flex-grow: 1;
    bottom: 8px;
    font-size: 12px;
}

.textOrder6 {
    position: relative;
    color: red;
    bottom: 8px;
    font-size: 12px;
}

.flexContainer5 {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

}

.flexContainer4 {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

}

.image {
    width: 60px;
}

.button2 {
    background: #151414;
    margin-top: 20px;
    color: white;
    text-align: center;
    border-radius: 7px;
    width: 300px;
    height: 50px;
}

@media (max-width: 391px) {

    img {
        width: 55px;
    }

    .textOrder {
        margin-top: 25px;
        font-size: 17px;
    }

    .wholePageContainer {
        display: flex;
        flex-flow: column;
    }

    .inputContainer {
        padding: 0;
        margin: 0;
    }

    .frontSideOfCard {
        display: flex;
        flex-flow: column;
        width: 240px;
        padding: 20px 30px 20px 20px;
        z-index: 1;
        top: 175px;
        left: 0;

    }

    .backSideOfCard {
        width: 290px;
        z-index: 0;
        top: -40px;
        left: 0;

    }

    .textOrder3 {
        position: relative;
        color: white;
        top: 20px;
        left: 235px;
    }

    .screenLeft {
        display: flex;
        flex-flow: column;
        max-height: 250px;
        min-width: 0;
        align-items: center;
    }

    .screenRight {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 90px;
    }

    .flexContainer4 {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: flex-start;
    }

}